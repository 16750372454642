import { ISearchConfig } from '@/components/SearchTable/index.d';

export const ERRORSTATE = [
  { label: '全部', value: '' },
  { label: '失败', value: '0' },
  { label: '成功', value: '1' },
  {label: '成功（警告）', value: '2'}
];

export const searchConfig: ISearchConfig[] = [
  // 用户名
  {
    category: 'sfdc_log',
    subclass: 'name',
    type: 'input',
    key: 'name'
  },
  // 员工号
  {
    category: 'sfdc_log',
    subclass: 'employeeId',
    type: 'input',
    key: 'employeeId'
  },
  // E-mail
  {
    category: 'sfdc_log',
    subclass: 'email',
    type: 'input',
    key: 'email'
  },
  // franchise
  {
    category: 'sfdc_log',
    subclass: 'franchise',
    type: 'input',
    key: 'franchise',
  },
  // 同步结果
  {
    category: 'sfdc_log',
    subclass: 'result',
    type: 'select',
    key: 'result',
    options: ERRORSTATE,
  },
  // 操作时间
  {
    category: 'sfdc_log',
    subclass: 'syncTime',
    extendType: 'datetimerange',
    type: 'datepicker',
    key: 'syncTime',
    valueformat: 'yyyy-MM-dd HH:mm:ss'
  }
];

export enum StatusCode {
  DRAFT = '草稿',
  PUBLISHED = '待生效',
  EFFECTIVE = '生效',
  EXPIRED = '已失效'
}
