

































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { DialogService } from '@/services/common/dialog.service';
import { searchConfig } from './pharmafield.config';
import { ISearchConfig } from '@/components/SearchTable/index.d';
import { UserUploadService } from '@/services/user-uploading';
import { cloneDeep, isArray } from 'lodash';
import OpenButtonDialog from '@/components/syncManagement/openButtonDialog.vue';
import moment from 'moment';
import { uuid } from '@/services/utils';
import { CloseStatus } from '@/services/common/models/dialog.interface';

@Component
export default class Consent extends Vue {
  public searchFormLoading: boolean = false;
  public loading: boolean = false;

  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  @Inject(DialogService) private dialog!: DialogService;
  private refreshing: boolean = true;
  private timer:any = null;
  private searchConfig: ISearchConfig[] = [];
  private updateTime: any = '';
  private updateStatus: any = 1;
  private visible: boolean = false;
  private tableData: Dict<any> = [];
  private eventList: any[] = [];
  private formsearch: any = {};
  private total: any = 0;
  private switchvalue: boolean = false;
  private SwitchButton: any = '';
  private parameter: any = {
    pageSize: 10,
    pageNum: 1,
    result: '',
    employeeId: '',
    email: '', // 邮箱
    name: '', // 名字。
    franchise: '',
    beginTime: '',
    endTime: ''
  };
  // 获取入参信息
  public async searchCriteria(val?: any): Promise<void> {
    this.parameter.pageNum = 1;
    this.parameter.pageSize = 10;
    this.parameter.name = val.name;
    this.parameter.employeeId = val.employeeId;
    this.parameter.franchise = val.franchise;
    this.parameter.email = val.email;
    this.parameter.result = val.result;
    if (val.syncTime) {
      this.parameter.beginTime = val.syncTime[0];
      this.parameter.endTime = val.syncTime[1];
    } else {
      this.parameter.beginTime = '';
      this.parameter.endTime = '';
    }
    this.getList();
  }
  public async getList(): Promise<void> {
    try {
      this.loading = true;
      // this.getLatestSyncTime();
      const params = this.parameter;
      var removePropertyOfNull = function (obj: { [x: string]: any }) {
        Object.keys(obj).forEach((item) => {
          if (!obj[item] || obj[item].length == 0) delete obj[item];
        });
        return params;
      };
      removePropertyOfNull(params);
      const res = await this.userUploadService.getsfdcPharma(params);
      if (!res) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      }
      const { records, current, total, size } = res;
      this.parameter.pageSize = size;
      this.total = total;
      this.parameter.pageNum = current;
      if (records && isArray(records)) {
        this.tableData = records;
      }
    } finally {
      this.loading = false;
    }
  }

  private async initData() {
    this.searchConfig = cloneDeep(searchConfig);
    try {
      this.loading = true;
      await this.getLatestSyncTime();
      await this.getList();
    } finally {
      this.loading = false;
    }
  }

  // 获取最后一次同步时间
  public async getLatestSyncTime() {
    var params = {
      type: 'syncPharma'
    };
    const res = await this.userUploadService.getsynchronization(params);
    //res.updateTime  (最后一次同步时间返回的参数)
    //作长整型转换

    if(res.time !== null ){
      var updateTimeFormat = moment(res.time).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss');
    this.updateTime = updateTimeFormat + '最后更新';
    }else{
      this.updateTime = null;
    }
    // this.updateTime = res.time;
  }

  private mounted(): void {
    this.initData();
    this.refreshTime();
  }

  private refreshTime() {
    const timer = setInterval(() => {
      console.log('timer', timer);
      const asyncStatus = this.getAsyncStatus().then(
        (res) => {
          console.log('有没有在同步:', res);
          if (res !== undefined && res !== null) {
            if (res == true) {
              console.log('同步中');
              this.updateTime = '数据同步中...';
              this.refreshing = true;
            } else {
              console.log('没有同步的！');
              this.getLatestSyncTime();
              this.refreshing = false;
              console.log('refreshing is false');
            }
            if (this.refreshing === false) {
              console.log('等待结束');
              this.getList();
              clearInterval(timer);
            }
          }
        },
        (err) => {
          console.log(err, '发生错误');
          clearInterval(timer);
        }
      );
    }, 5 * 1000);
  }

  //获取当前同步状态 0未同步 1同步中 2同步完成
  private async getAsyncStatus() {
    var params = {
      type: 'syncPharma'
    };
    const res = await this.userUploadService.getsynchronization(params);
    // debugger;
    if (res.result == 1) {
      return true;
    } else {
      return false;
    }
  }

  public handleSizeChange(pageSize: number) {
    this.parameter.pageSize = pageSize;
    this.getList();
  }
  public handleCurrentChange(pageNumber: number) {
    this.parameter.pageNum = pageNumber;
    this.getList();
  }

  private async openButtonDialog() {
    const key = uuid();
    const res = await this.dialog.open(
      '同步开关',
      OpenButtonDialog,
      {
        key,
        switchvalue: this.switchvalue,
        fieldType: 'syncPharma'
      },
      {
        width: '25vw',
        height: '24vh'
      }
    );
    console.log('res', res);
    if (res.action == CloseStatus.confirm) {
      console.log('starting--------------');
      this.refreshTime();
      console.log('stopp--------------');
    }
  }

  private selectChange(val: Dict<any>): void {
    this.parameter = val;
  }

    beforeDestroy(){
    this.refreshing = false;
}

  
}
